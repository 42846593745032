import { useRouter } from "next/router";
import { useEffect } from "react";
import { editorPageRefreshRecovery } from "../lib/editorPageRefreshRecovery";
import Page from "../components/Page";
import { useUserContext } from "../contexts/user-context";

export default function EntryPoint() {
  const router = useRouter();
  const [user] = useUserContext();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!user.acceptedTerms) {
      router.replace("/welcome");
    } else if (editorPageRefreshRecovery.has()) {
      router.replace(`/editor/${editorPageRefreshRecovery.get()}`);
    } else {
      router.replace("/library");
    }
  }, [user]);

  return <Page title="Welcome to Moises AI Songwriter" status="loading" />;
}
