import { CircularProgress, Link } from "@mui/material";
import { ReactNode } from "react";
import styles from "./MessagePage.module.css";

type MessagePageProps = {
  loading?: boolean;
  children?: ReactNode;
};

type LoadingMessagePageProps = {
  text: string;
};

function MessagePage({ loading, children }: MessagePageProps) {
  return (
    <div className={styles.message}>
      {loading && <CircularProgress size={40} />}
      <p>{children}</p>
    </div>
  );
}

function LoadingMessagePage({ text }: LoadingMessagePageProps) {
  return <MessagePage loading>{text}</MessagePage>;
}

function ErrorMessagePage() {
  return (
    <MessagePage>
      Something went wrong.
      <br />
      Please, reload the page or return to the{" "}
      <Link href="/library">library</Link>.
    </MessagePage>
  );
}

MessagePage.Loading = LoadingMessagePage;

MessagePage.Error = ErrorMessagePage;

export default MessagePage;
