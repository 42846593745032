import Head from "next/head";
import { MobilePlaceholder } from "./MobilePlaceholder";
import styles from "./Page.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { useRouter } from "next/router";
import { join } from "../utils/next-utils";
import { Localization } from "../lib/localization";
import { config } from "../config";
import MessagePage from "./pages/MessagePage";
import { Status } from "../types/common";
import { ParentProps } from "../types/props";
import { ReactNode, useEffect } from "react";
import { useInitialValue } from "../hooks/use-initial-value";
import { useLingui } from "@lingui/react";

type PageProps = ParentProps & {
  title?: string;
  enableBackButton?: boolean;
  allowMobile?: boolean;
  status?: Status;
  loadingText?: string;
  /**
   * Condition evaluated after the first render. When present, the component will have loading
   * state initially.
   */
  startLoading?: boolean;
  error?: ReactNode;
  localization?: Localization;
};

/**
 * Page wrapper component.
 *
 * There are two ways of controlling the loading state:
 * 1. Through the status prop - the page is loading whenever the prop value is "loading";
 * 2. And through the startLoading prop - when present, and true, the page is initially loading and
 *    changes its state accordingly to the status prop on the next renderings.
 *
 * The second approach is useful to SSR the page loading when it depends on some condition that is
 * evaluated in the client side only. The first approach can't be used in this case because the SSR
 * result will differ from the client side's one and cause errors.
 *
 * @example
 * // The page will be loading as long as the status is "loading"
 * <Page status={status} />
 *
 * @example
 * // Page will be loading on the first render and keep loading if the status is "loading"
 * <Page status={status} startLoading />
 */
function Page({
  title,
  enableBackButton,
  allowMobile,
  status = "succeeded",
  loadingText = "Loading...",
  startLoading,
  error = <MessagePage.Error />,
  localization,
  children,
}: PageProps) {
  const { i18n } = useLingui();
  const router = useRouter();
  const resolvedAllowMobile = allowMobile || config.disableMobilePlaceholder;
  const resolvedStatus = useInitialValue(
    // Considered loading only on the first render
    startLoading ? "loading" : status,
    status
  );

  useEffect(() => {
    if (!localization) {
      return;
    }

    const { locale, catalogs } = localization;

    if (locale) {
      i18n.load({ [locale]: catalogs[locale]! });

      if (i18n.locale !== locale) {
        i18n.activate(locale);
      }
    }
  }, [localization?.locale, i18n]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {!resolvedAllowMobile && <MobilePlaceholder />}

      <div
        className={join(
          styles.content,
          resolvedAllowMobile && styles.allowMobile
        )}
      >
        {enableBackButton && (
          <div className={join(styles.backButton, styles.hideInMobile)}>
            <IconButton onClick={() => router.push("/library")}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        )}

        {resolvedStatus === "loading" && (
          <MessagePage.Loading text={loadingText} />
        )}

        {resolvedStatus === "failed" && error}

        {resolvedStatus === "succeeded" && children}
      </div>
    </>
  );
}

export default Page;
