import { Link } from "@mui/material";
import styles from "./MobilePlaceholder.module.css";

export function MobilePlaceholder() {
  return (
    <div className={styles.root}>
      <div className={styles.backgroundLight}></div>

      <div className={styles.container}>
        <div className={styles.content}>
          <h2>
            AI creative writing is currently available for
            <span className={styles.highlight}> desktop</span>
          </h2>

          <p>
            Join the waitlist for mobile access and start writing <b>today</b>{" "}
            on desktop at
            <span className={styles.highlight}> studio.moises.ai/composer</span>
          </p>

          <Link
            className={styles.applyButton}
            href="https://moises.app/mobilecomposer"
            underline="none"
            alt="Join mobile experience waitlist"
          >
            Join mobile experience waitlist
          </Link>
        </div>

        <div className={styles.computerImage}></div>
      </div>
    </div>
  );
}
