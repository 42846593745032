import Constants from "../constants";

const { STORAGE_KEY, LYRICS_KEY } = Constants;

const getUserIDInSession = (): string => {
  const getUserInSession = sessionStorage.getItem(STORAGE_KEY);
  return getUserInSession ? JSON.parse(getUserInSession).userId : null;
};

const setPageEditor = (id: string): void => {
  const update = {
    userID: getUserIDInSession(),
    lyricsID: id,
  };
  sessionStorage.setItem(LYRICS_KEY, JSON.stringify(update));
};

const getPageEditor = (): string | null => {
  const lyricsSession = sessionStorage.getItem(LYRICS_KEY);
  const updateLyrics = lyricsSession ? JSON.parse(lyricsSession) : null;
  if (getUserIDInSession() !== updateLyrics?.userID) return null;
  return updateLyrics.lyricsID;
};

const removePageEditor = () => sessionStorage.removeItem(LYRICS_KEY);
const hasPageEditor = (): boolean => Boolean(getPageEditor());

export const editorPageRefreshRecovery = {
  set: setPageEditor,
  get: getPageEditor,
  remove: removePageEditor,
  has: hasPageEditor,
};
